module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lou Marvin Caraig - Blog","short_name":"Lou Marvin Caraig - Blog","start_url":"/","display":"standalone","icon":"static/favicon.jpeg","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"233e133ce09f292885dc44f0f8d5c6e9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-49122702-2"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":970,"wrapperStyle":"height: 100%;"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
